/**
 * src/api/http.js
 */
import axios from "axios";
import qs from "qs";
import notify from "utils/notifications";
import history from "utils/history";
import packageJson from "../../../package.json";
const { REACT_APP_SERVER_URL } = process.env;

// axios instance
const instance = axios.create({
  baseURL: `${REACT_APP_SERVER_URL}`,
  paramsSerializer(params) {
    return qs.stringify(params, { indices: false });
  },
});

// request header
instance.interceptors.request.use(
  (config) => {
    let token =
      window.location.pathname === "/privacy-policy"
        ? sessionStorage.getItem("token")
        : localStorage.getItem("token") || "";
    token =
      token?.includes('"') && token?.startsWith('"')
        ? token.replaceAll('"', "")
        : token;
    config.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${localStorage.getItem('token')} `,
      Authorization: `Bearer ${token}`,
      webversioncode: packageJson.versionCode,
      platform: "cnaps-web",
      version: packageJson.version,
      domain: localStorage.getItem("domain"),
    };

    return config;
  },
  (error) => Promise.reject(error)
);

//parse error response
function parseError(messages) {
  if (messages) {
    if (messages instanceof Array) {
      return Promise.reject({ messages });
    }
    return Promise.reject({ messages: [messages] });
  }
  return Promise.reject({ messages: ["got errors"] });
}

//parse response
function parseBody(response) {
  if (response.status === 200) {
    return response;
  } else {
    return parseError(response.data.messages);
  }
}

// response parse
let isRedirecting = false; // Flag to track redirection and how often pushed to /error

instance.interceptors.response.use(
  (response) => parseBody(response),
  (error) => {
    if (error.response && error.response.status === 401) {
      if (!isRedirecting) {
        isRedirecting = true; // Set flag to true to prevent multiple redirects
        error?.response?.data?.message !=='Session expired' && notify("error", error?.response?.data?.message);
        localStorage.removeItem("token");
        localStorage.removeItem("login-type");
        localStorage.removeItem("token-data");
        
        history.replace({
          pathname: "/error",
          state: {
            message: error?.response?.data?.message || "Session Expired",
            statusCode: error.response.status,
            errors: error?.response?.data,
          },
        });
        
        // Optional: Reset the flag after a short delay to allow future redirects
        setTimeout(() => {
          isRedirecting = false;
        }, 1000); // Adjust delay as needed
      }
    } else if (error.response && error.response.status === 500) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(error);
    }
  }
);


export const http = instance;

export default http;
